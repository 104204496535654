import React from "react";

function Carousel({ handleScroll, slides, currentSlide }) {
  return (
    <div className="carousel-container">
      {/* Swipeable carousel on the whole card-content */}
      <div className="carousel" onScroll={handleScroll}>
        {/* Loop through slides array and create a slide div for each one */}
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            {slide.type === "image" ? (
              <img className="image" src={slide.url} alt={slide.name} />
            ) : (
              <div className={` ${slide.class} full`}>{slide.content}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
