// CAPITAL CITIES
// Source: https://restcountries.com/v3.1/all

// ANATOMY DIAGRAMS
// Source: /anatomy.json

// JITHUB TECHNIQUES
// Source: https://bjj.oweng.co.uk/techniques.json

// JITHUB GLOSSARY
// Source: https://bjj.oweng.co.uk/glossary.json

// WORLD FLAGS
// Source: https://restcountries.com/v3.1/all

// BIRDS
// Source: https://www.allaboutbirds.org/guide/browse/taxonomy

// FLOWERS AND TREES AND PLANTS
// Source: https://www.gardeningknowhow.com/garden-how-to/info/plant-lists.htm

// MUSIC THEROY
// Source: https://www.musictheory.net/lessons

const config = {
  sources: [
    {
      name: "Geography",
      type: "api",
      url: "https://restcountries.com/v3.1/all",
      username: "geography",
      process: (data) =>
        data.map((country) => ({
          username: "Geography",
          avatar: "geo.png",
          slides: [
            { type: "text", content: country.flag, class: "flag" },
            { type: "text", content: country.name.common, class: "question" },
            {
              type: "text",
              content: country.capital ? country.capital[0] : "N/A",
              class: "question",
            },
          ],
          caption: `Did you know that ${country.name.common} is a country in ${
            country.region
          } and that its capital is ${
            country.capital ? country.capital[0] : "N/A"
          }?`,
        })),
    },
    {
      name: "Philosophy",
      type: "json",
      url: "feeds/philosophy.json", // Correct URL for the JSON file
      username: "philosophy",
      process: (data) => {
        // Ensure the `techniques` key exists and it's an array
        if (data && Array.isArray(data)) {
          return data.map((snippet) => ({
            username: "Philosophy",
            avatar: "words.jpg",

            content: snippet.type === "quote" && `"` + snippet.content + `"`,
            class: "question",
            author: snippet.author,
            caption:
              "From " +
              snippet.source +
              " by " +
              snippet.author +
              " (" +
              snippet.movement +
              ")", // Optional, you can add more fields if needed
          }));
        } else {
          console.error("Expected 'words' array but got:", data);
          return []; // Return an empty array if `techniques` isn't present or isn't an array
        }
      },
    },
    {
      name: "Vocabulary",
      type: "json",
      url: "feeds/words2.json", // Correct URL for the JSON file
      username: "words",
      process: (data) => {
        // Ensure the `techniques` key exists and it's an array
        if (data && Array.isArray(data)) {
          return data.map((word) => ({
            username: "Words",
            avatar: "words.jpg",
            slides: [
              { type: "text", content: word.word, class: "question" },
              // LOOP THROUGH word.definitions AND CREATE SLIDES
              ...word.definitions.map((definition, index) => ({
                type: "text",
                content: definition,
                class: `definition slide-${index + 1}`,
              })),
            ],
            caption: word.etymologies, // Optional, you can add more fields if needed
          }));
        } else {
          console.error("Expected 'words' array but got:", data);
          return []; // Return an empty array if `techniques` isn't present or isn't an array
        }
      },
    },
    {
      name: "Anatomy",
      type: "json",
      url: "feeds/anatomy.json", // Correct URL for the JSON file
      username: "anatomy",
      process: (data) => {
        // Ensure the `anatomy` key exists and it's an array
        if (data && Array.isArray(data)) {
          return data.map((anatomy) => ({
            username: "Anatomy",
            avatar: "anatomy.jpg",
            slides: [
              {
                type: "image",
                url: `img/anatomy/${anatomy.imgUrl}`,
              },
              {
                type: "text",
                content: anatomy.name,
                class: "question",
              },
            ],
            caption:
              "With regard to  " +
              anatomy.type +
              ", what does this image show?", // Optional, you can add more fields if needed
          }));
        } else {
          console.error("Expected 'anatomy' array but got:", data);
          return []; // Return an empty array if `anatomy` isn't present or isn't an array
        }
      },
    },
    {
      name: "Brazilian Jiu Jitsu",
      type: "json",
      username: "jithub",
      url: "https://bjj.oweng.co.uk/techniques.json", // Correct URL for the JSON file
      process: (data) => {
        // Ensure the `techniques` key exists and it's an array
        if (data.techniques && Array.isArray(data.techniques)) {
          return data.techniques.map((technique) => ({
            username: "JitHub",
            avatar: "drillr.png",
            content: technique.playlistId,
            caption: technique.name + ": " + technique.description, // Optional, you can add more fields if needed
            type: "ytpl",
          }));
        } else {
          console.error("Expected 'techniques' array but got:", data);
          return []; // Return an empty array if `techniques` isn't present or isn't an array
        }
      },
    },
    // {
    //   name: "BJJ and Judo Glossary",
    //   type: "json",

    //   url: "https://bjj.oweng.co.uk/glossary.json", // Correct URL for the JSON file
    //   process: (data) => {
    //     if (data && Array.isArray(data)) {
    //       return data.map((term) => ({
    //         username: "JitHub",
    //         avatar: "drillr.png",
    //         content: term.term,
    //         caption: term.definition + ": " + term.notes, // Optional, you can add more fields if needed
    //       }));
    //     } else {
    //       console.error("Expected 'techniques' array but got:", data);
    //       return []; // Return an empty array if `techniques` isn't present or isn't an array
    //     }
    //   },
    // },
    // {
    //   name: "YouTube Channel Feed",
    //   type: "api",
    //   url: `https://www.googleapis.com/youtube/v3/channels?part=id&forUsername=christmasswim&key=AIzaSyCnkfktadGgCiEBoVHufQyuyDXNsSjj0G4`,
    //   process: (channelData) => {
    //     // If no channel data is found, return an empty array
    //     if (
    //       !channelData.items ||
    //       !Array.isArray(channelData.items) ||
    //       channelData.items.length === 0
    //     ) {
    //       console.error("No channel found for the provided username.");
    //       return [];
    //     }

    //     const channelId = channelData.items[0].id;
    //     const videoUrl = `https://www.googleapis.com/youtube/v3/search?key=AIzaSyCnkfktadGgCiEBoVHufQyuyDXNsSjj0G4&channelId=${channelId}&part=snippet,id&order=date&maxResults=10`;

    //     // Fetch video data synchronously using the fetch API
    //     return fetch(videoUrl)
    //       .then((response) => response.json())
    //       .then((videoData) => {
    //         if (!videoData.items || !Array.isArray(videoData.items)) {
    //           console.error("No videos found for the channel.");
    //           return [];
    //         }

    //         // Process video data and return mapped array
    //         return videoData.items.map((item) => ({
    //           username: "YouTube Channel", // Can be customized to the channel's actual name
    //           content: item.id.videoId || "", // Video ID for embedding
    //           caption: item.snippet.title || "", // Video title
    //           type: "ytv", // Type for YouTube video
    //           imageUrl: item.snippet.thumbnails?.high?.url || "", // Video thumbnail URL
    //         }));
    //       })
    //       .catch((error) => {
    //         console.error("Error fetching YouTube videos:", error);
    //         return []; // Return empty array in case of error
    //       });
    //   },
    // },

    // {
    //   name: "Yoga Poses",
    //   type: "api",
    //   url: "https://lightning-yoga-api.herokuapp.com/yoga_poses", // Yoga poses API URL
    //   process: (data) =>
    //     data.map((pose) => ({
    //       username: "Yoga Poses",
    //       content: `${pose.english_name}`, // The name of the pose in English
    //       caption: `Pose: ${pose.english_name} (Sanskrit: ${pose.sanskrit_name})\nDifficulty: ${pose.difficulty}\nBenefits: ${pose.benefits}`, // Details about the pose
    //       imageUrl: pose.img_url, // URL to an image of the pose if available
    //     })),
    // },

    // {
    //   name: "Pub Quiz Trivia",
    //   type: "api",
    //   avatar: "pub_quiz.jpg", // You can add an appropriate avatar image
    //   url: "https://opentdb.com/api.php?amount=10", // Fetches 10 trivia questions
    //   process: (data) => {
    //     if (data.results && Array.isArray(data.results)) {
    //       return data.results.map((question) => ({
    //         username: "Pub Quiz",
    //         question: question.question,
    //         slides: question.correct_answer,
    //         caption: `Category: ${question.category}`,
    //       }));
    //     } else {
    //       console.error("Expected 'results' array but got:", data);
    //       return []; // Return empty array if no questions are found
    //     }
    //   },
    // },
  ],
  settings: {
    shufflePosts: true,
  },
};

export default config;
