import React from "react";
import Back from "./Back";

export default function ProfileHeader({ username }) {
  return (
    <div className="profile-header">
      <Back />
      <div className="profile-header__info">
        <h2>{username}</h2>
        <div className="profile-header__posts">Posts</div>
      </div>
      <div>.</div>
    </div>
  );
}
