import React, { useState, useEffect, useRef, useCallback } from "react";
import Menu from "./Menu";
import TriviaCard from "./TriviaCard";
import config from "./config";
import "./App.css";
import Header from "./Header";

const POSTS_PER_PAGE = 10;

const TriviaFeed = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [enabledFeeds, setEnabledFeeds] = useState({});

  const loaderRef = useRef(null);

  // Load feeds from localStorage and update enabledFeeds
  useEffect(() => {
    const storedFeeds = JSON.parse(localStorage.getItem("enabledFeeds")) || {};
    const initialFeeds = {};

    config.sources.forEach((feed) => {
      initialFeeds[feed.name] =
        storedFeeds[feed.name] !== undefined ? storedFeeds[feed.name] : true;
    });

    setEnabledFeeds(initialFeeds); // Here, we are using setEnabledFeeds to initialize the feeds
  }, []); // Runs only once when the component is first mounted

  // Function to shuffle posts with randomness
  const shuffleArray = (array) => {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };

  // Fetch posts from the enabled feeds
  const fetchData = useCallback(async () => {
    const fetchedPosts = [];
    setLoading(true);

    for (let source of config.sources) {
      if (enabledFeeds[source.name]) {
        try {
          const response = await fetch(source.url);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();
          fetchedPosts.push(...source.process(data));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }

    const shuffledPosts = shuffleArray(fetchedPosts);
    setAllPosts(shuffledPosts);
    setVisiblePosts([]); // Clear visible posts so the feed reloads
    setPage(1); // Reset the page to 1
    setLoading(false);
  }, [enabledFeeds]);

  // Function to refresh the feed (scroll to top + fetchData)
  // const handleRefresh = () => {
  //   console.log("Refreshing feed...");
  //   window.scrollTo(0, 0); // Scroll to the top
  //   fetchData(); // Refetch the data
  // };

  useEffect(() => {
    fetchData();
  }, [enabledFeeds, fetchData]); // Now fetchData is stable across re-renders

  // Load more posts when the page changes
  useEffect(() => {
    const startIndex = (page - 1) * POSTS_PER_PAGE;
    const endIndex = startIndex + POSTS_PER_PAGE;
    setVisiblePosts((prevPosts) => [
      ...prevPosts,
      ...allPosts.slice(startIndex, endIndex),
    ]);
  }, [page, allPosts]);

  // Infinite scrolling
  useEffect(() => {
    const currentLoaderRef = loaderRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 }
    );

    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [loading]);

  return (
    <>
      <Menu onRefresh={fetchData} /> {/* Pass the refresh function to Menu */}
      <Header />
      <div className="feed">
        {visiblePosts.map((post, index) => (
          <TriviaCard
            key={index}
            username={post.username}
            content={post.content}
            imageUrl={post.imageUrl}
            caption={post.caption}
            type={post.type}
            avatar={post.avatar}
            slides={post.slides}
            author={post.author}
          />
        ))}
        {loading && (
          <div>
            <div class="skeleton">
              <div class="skeleton-square"></div>
              <div class="skeleton-caption"></div>
            </div>
            <div class="skeleton">
              <div class="skeleton-square"></div>
              <div class="skeleton-caption"></div>
            </div>
            <div class="skeleton">
              <div class="skeleton-square"></div>
              <div class="skeleton-caption"></div>
            </div>
          </div>
        )}
        <div ref={loaderRef} className="loading-trigger"></div>
      </div>
    </>
  );
};

export default TriviaFeed;
