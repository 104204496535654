import React, { useState, useEffect } from "react";
import Back from "./Back";

const FeedToggle = ({ config, onToggle }) => {
  const [enabledFeeds, setEnabledFeeds] = useState({});

  useEffect(() => {
    const storedFeeds = JSON.parse(localStorage.getItem("enabledFeeds")) || {};
    const initialFeeds = {};
    config.sources.forEach((feed) => {
      initialFeeds[feed.name] =
        storedFeeds[feed.name] !== undefined ? storedFeeds[feed.name] : true;
    });
    setEnabledFeeds(initialFeeds);
    if (onToggle) {
      onToggle(initialFeeds);
    }
  }, [config.sources, onToggle]);

  const handleToggle = (feedName) => {
    const updatedFeeds = {
      ...enabledFeeds,
      [feedName]: !enabledFeeds[feedName],
    };
    setEnabledFeeds(updatedFeeds);
    localStorage.setItem("enabledFeeds", JSON.stringify(updatedFeeds));
    if (onToggle) {
      onToggle(updatedFeeds);
    }
  };

  return (
    <div>
      <Back />
      <div class="configure">
        <div className="feed-toggle">
          <h2>Configure Feeds</h2>
          {config.sources.map((feed) => (
            <div key={feed.name} className="feed-toggle-item">
              <label>
                {/* Ensure this is visible */}
                <div className="switch">
                  <input
                    type="checkbox"
                    checked={enabledFeeds[feed.name] || false}
                    onChange={() => handleToggle(feed.name)}
                  />
                  <span className="slider round"></span>
                </div>
                <span>{feed.name}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeedToggle;
