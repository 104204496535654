import React from "react";
import { Link } from "react-router-dom";

export default function Back() {
  return (
    <Link to="/" className="back-button">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M15 18l-6-6 6-6" />
      </svg>
    </Link>
  );
}
