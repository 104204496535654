import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TriviaFeed from "./TriviaFeed";
import FeedToggle from "./FeedToggle";
import config from "./config";
// import Header from "./Header";
import Menu from "./Menu";
import "./App.css";
import UserProfileFeed from "./UserProfileFeed";

const App = () => {
  // Function to handle toggling feeds (you might want to save this to local storage as needed)
  const handleToggleFeeds = (updatedFeeds) => {
    console.log("Updated feeds:", updatedFeeds);
    // Optionally save it to localStorage or update global state
    localStorage.setItem("enabledFeeds", JSON.stringify(updatedFeeds));
  };

  return (
    <Router>
      <div className="app">
        <Routes>
          {/* Route for Home (Trivia Feed) */}
          <Route path="/" element={<TriviaFeed />} />
          {/* Route for Feed Toggle, passing the handleToggleFeeds function as onToggle */}
          <Route
            path="/feed-toggle"
            element={
              <FeedToggle config={config} onToggle={handleToggleFeeds} />
            }
          />
          <Route path="/:username" element={<UserProfileFeed />} />
        </Routes>
        <Menu />
      </div>
    </Router>
  );
};

export default App;
