import React from "react";

function CardIcons({ handleLikeToggle, isLiked }) {
  return (
    <div className="card-icons">
      {/* Updated Heart Icon - Toggle red fill on like */}
      <svg
        onClick={handleLikeToggle}
        style={{ cursor: "pointer", fill: isLiked ? "red" : "none" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
          stroke={isLiked ? "none" : "currentColor"}
          fill={isLiked ? "red" : "none"}
          strokeWidth="2"
        />
      </svg>

      <svg>
        <path
          d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z"
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth="2"
        ></path>
      </svg>

      <svg>
        <polygon
          fill="none"
          points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeWidth="2"
        ></polygon>
      </svg>
    </div>
  );
}

export default CardIcons;
