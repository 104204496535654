import React, { useState, useEffect } from "react";
import Carousel from "./components/Carousel";
import CardIcons from "./components/CardIcons";
import { useNavigate } from "react-router-dom";
// Function to generate a unique key for each item
const getItemKey = (item) => {
  // Prioritize 'content' field if available, otherwise use 'username' + 'caption' or other combinations.
  return (
    item.content ||
    `${item.username}-${item.caption || ""}`.replace(/\s+/g, "-")
  );
};

const TriviaCard = ({
  username,
  content,
  imageUrl,
  caption,
  type,
  emoji,
  question,
  answer,
  avatar,
  slides,
  author,
}) => {
  const itemKey = getItemKey({ username, content, caption }); // Generate unique key for the item
  const [isLiked, setIsLiked] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0); // Track current slide for the carousel

  // Check localStorage to see if the item has been liked
  useEffect(() => {
    const likedStatus = localStorage.getItem(`liked-${itemKey}`);
    setIsLiked(likedStatus === "true");
  }, [itemKey]);

  // Handle liking/unliking the item
  const handleLikeToggle = () => {
    const newLikedStatus = !isLiked;
    setIsLiked(newLikedStatus);
    localStorage.setItem(`liked-${itemKey}`, newLikedStatus); // Save the status in localStorage
  };

  // Handle scroll event to update current slide index
  const handleScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    const width = e.target.clientWidth;
    const newIndex = Math.round(scrollLeft / width);
    setCurrentSlide(newIndex); // Update current slide index
  };

  const navigate = useNavigate();

  const handleHeaderClick = () => {
    const lowerUsername = username.toLowerCase();
    navigate(`/${lowerUsername}`);
  };

  // Render the iframe or other content based on the type
  const renderContent = () => {
    if (type === "ytpl") {
      return (
        <iframe
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/videoseries?list=${content}`}
          title="YouTube playlist"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else if (type === "ytv") {
      return (
        <iframe
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${content}`}
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else if (slides) {
      return (
        <Carousel
          handleScroll={handleScroll}
          question={question}
          slides={slides}
        />
      );
    } else {
      console.log(content);
      return (
        <div>
          <div className="content">{content}</div>
          {author && <div className="author">{author}</div>}
        </div>
      );
    }
  };

  return (
    <div className="card">
      <div className="card-header" onClick={handleHeaderClick}>
        {avatar ? (
          <img src={`img/${avatar}`} alt={username} className="avatar" />
        ) : (
          <div className="avatar-placeholder"></div>
        )}
        <h3>{username}</h3>
      </div>

      <div
        className={`card-content carousel-container ${
          slides ? "swipeable" : ""
        }`}
        onScroll={handleScroll}
      >
        {renderContent()}
      </div>

      {/* Dots for navigation */}
      {slides && (
        <div className="dots">
          {[...Array(slides.length)].map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
            ></span>
          ))}
        </div>
      )}

      <CardIcons handleLikeToggle={handleLikeToggle} isLiked={isLiked} />

      {caption && (
        <div className="card-caption">
          <strong>{username} </strong>
          {caption}
        </div>
      )}
    </div>
  );
};

export default TriviaCard;
