import React from "react";
import { NavLink } from "react-router-dom";

export default function Menu({ onRefresh }) {
  return (
    <div className="menu">
      {/* Home Icon */}
      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive ? "menu-item active" : "menu-item"
        }
        onClick={(e) => {
          if (e.target.closest(".menu-item.active")) {
            console.log("Already at home");
            e.preventDefault(); // Prevent default navigation if already active
            window.scrollTo(0, 0); // Scroll to the top
            if (onRefresh) {
              onRefresh(); // Refresh the feed
            } else {
              console.log("onRefresh is undefined"); // Debugging log
            }
          }
        }}
      >
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M22 23h-6.001a1 1 0 0 1-1-1v-5.455a2.997 2.997 0 1 0-5.993 0V22a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V11.543a1.002 1.002 0 0 1 .31-.724l10-9.543a1.001 1.001 0 0 1 1.38 0l10 9.543a1.002 1.002 0 0 1 .31.724V22a1 1 0 0 1-1 1Z" />
        </svg>
      </NavLink>

      {/* Feed Toggle Icon */}
      <NavLink
        to="/feed-toggle"
        className={({ isActive }) =>
          isActive ? "menu-item active" : "menu-item"
        }
      >
        <svg width="24px" height="24px" viewBox="0 0 16 16">
          <path d="M3 1H1V3H3V1Z"></path>
          <path d="M3 5H1V7H3V5Z"></path>
          <path d="M1 9H3V11H1V9Z"></path>
          <path d="M3 13H1V15H3V13Z"></path>
          <path d="M15 1H5V3H15V1Z"></path>
          <path d="M15 5H5V7H15V5Z"></path>
          <path d="M5 9H15V11H5V9Z"></path>
          <path d="M15 13H5V15H15V13Z"></path>
        </svg>
      </NavLink>
    </div>
  );
}
