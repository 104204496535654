import React, { useState, useEffect, useRef, useCallback } from "react";
import TriviaCard from "./TriviaCard";
import { useParams } from "react-router-dom";
import config from "./config";
import ProfileHeader from "./ProfileHeader";

const POSTS_PER_PAGE = 10; // Number of posts to load at a time

const UserProfileFeed = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const loaderRef = useRef(null); // Ref for infinite scrolling
  const { username } = useParams(); // Get the username from the route

  // Fetch all posts for the selected user
  useEffect(() => {
    const fetchPosts = async () => {
      const userPosts = [];
      const userFeed = config.sources.find(
        (source) => source.username === username
      );

      if (userFeed) {
        try {
          const response = await fetch(userFeed.url);
          if (response.ok) {
            const data = await response.json();
            const processedPosts = userFeed.process(data);
            userPosts.push(...processedPosts.reverse()); // Reverse the posts for profile feed order
          } else {
            console.error("Failed to fetch posts:", response.status);
          }
        } catch (error) {
          console.error("Error fetching posts:", error);
        }
      } else {
        console.error("No feed found for username:", username);
      }

      setAllPosts(userPosts);
      setLoading(false);
      // Load the initial batch of posts
      setVisiblePosts(userPosts.slice(0, POSTS_PER_PAGE));
    };

    fetchPosts();
  }, [username]);

  // Function to load more posts
  const loadMorePosts = useCallback(() => {
    const currentLength = visiblePosts.length;
    const nextPosts = allPosts.slice(
      currentLength,
      currentLength + POSTS_PER_PAGE
    );
    if (nextPosts.length > 0) {
      setVisiblePosts((prevPosts) => [...prevPosts, ...nextPosts]);
    }
  }, [visiblePosts.length, allPosts]);

  // Infinite scrolling
  useEffect(() => {
    const currentLoaderRef = loaderRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          !loading &&
          visiblePosts.length < allPosts.length
        ) {
          loadMorePosts();
        }
      },
      { threshold: 1 }
    );

    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [loading, visiblePosts.length, allPosts.length, loadMorePosts]);

  return (
    <div>
      <ProfileHeader username={username} />
      <div className="feed">
        <div className="feed-container">
          {loading && (
            <div>
              <div className="skeleton">
                <div className="skeleton-square"></div>
                <div className="skeleton-caption"></div>
              </div>
              <div className="skeleton">
                <div className="skeleton-square"></div>
                <div className="skeleton-caption"></div>
              </div>
              <div className="skeleton">
                <div className="skeleton-square"></div>
                <div className="skeleton-caption"></div>
              </div>
            </div>
          )}
          {visiblePosts.map((post, index) => (
            <TriviaCard
              key={index}
              username={post.username}
              content={post.content}
              imageUrl={post.imageUrl}
              caption={post.caption}
              type={post.type}
              question={post.question}
              answer={post.answer}
              emoji={post.emoji}
              avatar={post.avatar}
              slides={post.slides}
              author={post.author}
            />
          ))}
          <div ref={loaderRef} className="loading-trigger"></div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileFeed;
